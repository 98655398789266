<template>
  <v-stepper
    id="workspace-stepper"
    v-model="e6"
  >
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-stepper-header>
      <v-stepper-step
        :complete="e6 > 1"
        step="1"
        dark
        color="color_green"
      >
        Workspace & Layers
      </v-stepper-step>
      <v-divider />
      <v-stepper-step
        :complete="e6 > 2"
        step="2"
        dark
        color="color_green"
      >
        Calls & Time
      </v-stepper-step>
      <v-divider />
      <v-stepper-step
        step="3"
        dark
        color="color_green"
      >
        Geographical domain
      </v-stepper-step>
    </v-stepper-header>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card
            flat
            class="mb-12"
          >
            <v-card-title>Workspace</v-card-title>
            <v-card-text>
              <v-select
                v-model="geoserver_data.workspace.workspace_name"
                label="Workspace"
                :items="geoserver_data.geoserverWorkspaces"
                :search-input.sync="searchWorkspace"
                color="color_green"
                outlined
                :placeholder="geoserver_data.workspace.workspace_name"
                :disabled="workspaceDisabled"
                hide-no-data
                append-inner-icon="mdi-check-outline"
                :rules="workspace_rules"
                required
                @change="checkClick"
              />
            </v-card-text>
            <div v-if="disableLayers == false">
              <layers-selector @toStepper="eventFromLayerSelector" />
            </div>
          </v-card>
          <v-row>
            <v-col cols="2">
              <v-btn
                dark
                color="warning"
                @click="emitEventToWSDialog"
              >
                Close
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col
              cols="3"
              class="text-right"
            >
              <v-btn
                dark
                color="color_green"
                :disabled="checkFormStep1()"
                @click="e6 = 2"
              >
                Continue
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
        <v-stepper-content step="2">
          <number-of-calls />
          <time-extension @settedTime="settedTime" />
          <v-row>
            <v-col cols="2">
              <v-btn
                dark
                color="warning"
                @click="emitEventToWSDialog"
              >
                Close
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col
              cols="5"
              class="text-right"
            >
              <v-btn
                text
                @click="e6 = 1"
              >
                Cancel
              </v-btn>
              <v-btn
                dark
                color="color_green"
                :disabled="checkFormStep2()"
                @click="goToDomain"
              >
                Continue
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
        <v-stepper-content step="3">
          <v-card
            flat
            class="mb-12"
          >
            <v-card outlined>
              <v-toolbar
                flat
                dark
                color="color_green"
              >
                <v-spacer />
                <v-toolbar-title> Domains </v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-virtual-scroll
                :items="geoserver_data.wsDomainsToAdd"
                bench="5"
                max-height="300"
                item-height="64"
              >
                <template v-slot:default="{ item }">
                  <v-list-item :key="item.domain_name">
                    <v-list-item-content>
                      <v-list-item-title>
                        Domain: {{ item.domain_id }} - {{ item.domain_name }}
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn icon>
                        <delete
                          :id="item.domain_name"
                          @deleted="deleteDomainWS(item.domain_id)"
                        />
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-virtual-scroll>
              <v-divider />
              <v-virtual-scroll
                :items="geoserver_data.wsDomains"
                bench="5"
                max-height="300"
                item-height="64"
              >
                <template v-slot:default="{ item }">
                  <v-list-item :key="item.domain_name">
                    <v-list-item-content>
                      <v-list-item-title>
                        Domain: {{ item.domain_id }} - {{ item.domain_name }}
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn icon>
                        <delete
                          :id="item.domain_name"
                          @deleted="deleteDomainWS(item.domain_id)"
                        />
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-virtual-scroll>
            </v-card>
            <v-card-actions>
              <v-row>
                <v-col cols="10">
                  <v-autocomplete
                    v-model="domainToAdd"
                    label="Domain"
                    :items="geoserver_data.domainsWS"
                    item-text="domain_name"
                    :search-input.sync="searchWorkspaceAutocomplete"
                    :filter="customFilter"
                    color="color_green"
                    outlined
                    hint="Click the icon to add"
                    return-object
                  >
                    <template v-slot:selection="{ item }">
                      <span
                        class="d-inline-block text-truncate"
                        style="max-width: 30em"
                      >
                        {{ item.domain_id }} - {{ item.domain_name }}
                      </span>
                    </template>
                    <template
                      v-slot:[`item`]="{ item }"
                      class="d-grid"
                    >
                      <span
                        class="d-inline-block text-truncate"
                        style="max-width: 30em"
                      >
                        {{ item.domain_id }} - {{ item.domain_name }}
                      </span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    height="55"
                    color="primary"
                    @click="addNewDomain"
                  >
                    <v-icon large>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
          <v-row>
            <v-col cols="2">
              <v-btn
                dark
                color="warning"
                @click="emitEventToWSDialog"
              >
                Close
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col
              cols="5"
              class="text-right"
            >
              <v-btn
                text
                @click="beforeDomain"
              >
                Cancel
              </v-btn>
              <v-btn
                dark
                color="primary"
                :disabled="checkFormStep3()"
                @click="confirmButtonEvent"
              >
                Confirm
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper-items>
    </v-form>
  </v-stepper>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { required } from '@/formRules.js';

export default {
  components: {
    LayersSelector: () => import('@/components/policy/LayersSelector'),
    TimeExtension: () => import('@/components/policy/TimeExtension'),
    NumberOfCalls: () => import('@/components/policy/NumberOfCalls'),
    delete: () => import('@/components/utils/Delete')
  },
  props: {
    workspaceDisabled: {
      type: Boolean,
      default: false
    },
    disableLayers: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      e6: 1,
      valid: true,
      searchWorkspace: '',
      time: false,
      searchWorkspaceAutocomplete: '',
      domainToAdd: null,
      workspace_rules: [...required],
      overlay: false
    };
  },
  computed: {
    ...mapState(['geoserver_data'])
  },
  created() {
    this.geoserver_data.geoserverLayers = [];
    this.time =
      this.geoserver_data.isModifingWorkspace == true ? true : this.time;
  },
  methods: {
    ...mapActions([
      'storeWSLayers',
      'insertWorkspaceName',
      'storeNewWorkspace',
      'storeUpdatedWorkspace',
      'storeDomainsWS',
      'storeDomainsByWS',
      'storeAddedDomain',
      'deleteListDomain',
      'updateLayerDBTable',
      'storeIsModifingWorkspace'
    ]),
    checkFormStep1() {
      if (
        !this.geoserver_data.workspace.workspace_name ||
        this.geoserver_data.chosenLayers <= 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    checkFormStep2() {
      if (
        !this.time ||
        this.geoserver_data.startTime === '' ||
        this.geoserver_data.stopTime === ''
      ) {
        return true;
      } else {
        return false;
      }
    },
    checkFormStep3() {
      if (
        this.geoserver_data.isModifingWorkspace &&
        this.geoserver_data.wsDomains.length <= 0
      ) {
        if (this.geoserver_data.wsDomainsToAdd.length <= 0) {
          return true;
        } else {
          return false;
        }
      } else {
        if (
          !this.geoserver_data.isModifingWorkspace &&
          this.geoserver_data.wsDomainsToAdd.length <= 0
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    settedTime() {
      this.time = true;
    },
    async checkClick() {
      this.geoserver_data.geoserverLayers = [];
      this.geoserver_data.chosenLayers = [];
      this.$emit('setWorkspaceDisabled', true);

      await this.storeWSLayers(this.geoserver_data.workspace.workspace_name);
      await this.insertWorkspaceName(
        this.geoserver_data.workspace.workspace_name
      );
      this.$emit('setDisableLayers', false);
    },
    eventFromLayerSelector() {
      this.geoserver_data.geoserverLayers = [];
      this.$emit('setWorkspaceDisabled', false);
      this.$emit('setDisableLayers', true);
    },
    async confirmButtonEvent() {
      this.overlay = true;

      if (this.geoserver_data.isModifingWorkspace == true) {
        this.e6 = 1;
        await this.storeUpdatedWorkspace();
        await this.updateLayerDBTable();
        this.geoserver_data.wsDomains = [];
        this.geoserver_data.domainsWS = [];
        await this.closeDialog();
      } else {
        this.e6 = 1;
        await this.storeNewWorkspace();
        await this.updateLayerDBTable();
        this.geoserver_data.wsDomains = [];
        this.geoserver_data.domainsWS = [];
        await this.closeDialog();
      }

      this.geoserver_data.wsDomainsToAdd = [];
    },
    async emitEventToWSDialog() {
      this.geoserver_data.geoserverLayers = [];
      this.geoserver_data.chosenLayers = [];
      this.insertWorkspaceName('');
      this.geoserver_data.callsNum = -1;
      this.geoserver_data.startTime = '';
      this.geoserver_data.stopTime = '';
      this.geoserver_data.dateType = 'static';
      this.geoserver_data.wsDomains = [];
      this.geoserver_data.domainsWS = [];

      this.e6 = 1;

      await this.storeIsModifingWorkspace(false);
      this.$emit('setDisableLayers', true);
      this.closeDialog();
    },
    async closeDialog() {
      this.domainToAdd = '';
      this.$refs.form.reset();
      this.valid = true;
      this.$emit('closeDialogEvent');
      this.overlay = false;
    },
    async goToDomain() {
      this.e6 = 3;
      this.geoserver_data.domainWS = [];
      await this.storeDomainsWS();
      await this.storeDomainsByWS();
    },
    beforeDomain() {
      this.e6 = 2;
      this.geoserver_data.wsDomains = [];
      this.geoserver_data.domainsWS = [];
    },
    async addNewDomain() {
      await this.storeAddedDomain({
        id: this.domainToAdd.domain_id,
        name: this.domainToAdd.domain_name
      });
    },
    async deleteDomainWS(domain_id) {
      const index = this.geoserver_data.wsDomainsToAdd.map(item => item.domain_id).indexOf(domain_id);

      if (index === -1) {
        await this.deleteListDomain({ domain_id: domain_id });
      } else {
        this.geoserver_data.wsDomainsToAdd.splice(index, 1);
      }
    },
    customFilter (item, queryText) {
      const textOne = item.domain_name.toLowerCase();
      const textTwo = item.domain_id.toString();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
    }
  }
};
</script>
